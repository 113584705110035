<template>
  <div
    v-if="imgFilter === true"
    class="filter-mobile-title mobile"
    :class="{ 'filter-mobile-sticky': scrollNavbar, 'nr-of-products': activeFilters?.length }"
  >
    <span v-if="activeFilters?.length" class="results">{{ products }} de rezultate</span>
    <span class="filter-close">
      <button v-if="products" @click="showSorting = true" class="btn sort-button rounded-pill">
        Sortează
      </button>
      <button
        v-if="hasFilters"
        class="btn filter-button rounded-pill mobile-filter-img"
        @click="showFilter"
      >
        <span>
          <span v-if="activeFilters?.length" class="no-of-filters"
            >{{ activeFilters.length }}
          </span>
          <img v-else class="filter-button-icon" src="@/assets/images/filter_icon.svg" />
        </span>
        <span>Filtreaza</span>
      </button>
    </span>
    <VueFinalModal
      v-model="showSorting"
      class="sort-modal"
      content-class="sort-modal-content"
      content-transition="vfm-fade"
    >
      <div class="sort-modal-header">
        <span class="main-title">
          Sortează după:
        </span>
        <button class="btn btn-close" @click="showSorting = false">
          <!-- <img src="@/assets/images/x-close.svg" /> -->
          OK
        </button>
      </div>
      <div class="sort-modal-body">
        <ul class="sort-products-list">
          <li v-if="!hideStock"><label class="dropdown-item"><input type="radio" name="sort1" value="in_stock|asc" v-model="sortByValue" @click="sortProducts('in_stock|asc')">În stoc</label></li>
          <li><label class="dropdown-item"><input type="radio" name="sort2" value="custom_promotion|asc" v-model="sortByValue" @click="sortProducts('custom_promotion|asc')">Oferte speciale</label></li>
          <li><label class="dropdown-item"><input type="radio" name="sort3" value="custom_popularity|desc" v-model="sortByValue" @click="sortProducts('custom_popularity|desc')">Popularitate</label></li>
          <li><label class="dropdown-item"><input type="radio" name="sort4" value="new" v-model="sortByValue" @click="sortProducts('new')">Noutăți</label></li>
          <li><label class="dropdown-item"><input type="radio" name="sort5" value="custom_price|asc" v-model="sortByValue" @click="sortProducts('custom_price|asc')">Preț crescător</label></li>
          <li><label class="dropdown-item"><input type="radio" name="sort6" value="custom_price|desc" v-model="sortByValue" @click="sortProducts('custom_price|desc')">Preț descrescător</label></li>
        </ul>
      </div>
      <div class="sort-modal-footer">
        <button class="btn btn-sort" @click="showSorting = false">Salveaza si vezi rezultate</button>
      </div>
    </VueFinalModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueFinalModal } from 'vue-final-modal';

export default {
  data() {
    return {
      scrollNavbar: false,
      showSorting: false,
      sortByValue: this.$route.params.sortby || '',
    };
  },
  components: {
    VueFinalModal,
  },
  name: "MobileFilter",
  props: ["products"],
  emits: ["showFilter"],
  computed: {
    ...mapGetters("filter", ["imgFilter"]),
    activeFilters() {
      return this.$store.getters["shop/propertyParams"];
    },
    isCategLoad() {
      return this.$store.getters["shop/categoryIsLoad"];
    },
    filter_params() {
      return this.$store.getters["shop/filter_params"];
    },
    currentPage() {
      return this.$store.getters["shop/page"];
    },
    categ() {
      return this.$store.getters["shop/category"];
    },
    hideStock() {
      if (this.isCategLoad && this.categ.labels) {
          var categHideStock = this.categ.labels.find((elem) => {
          return elem.code === "hide-stock";
        });
      }
      if(categHideStock) {
        return 1;
      } else {
        return 0;
      }
    },
    hasFilters() {
      var filters = this.$store.getters["shop/filters"];
      if(filters?.length > 0 || this.categ.layout == "with_filter") {
        return true;
      } else {
        return false;
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.sortByValue = to.params.sortby || '';
    next();
  },
  methods: {
    hideImg() {
      // console.log('testimg');
      this.$store.dispatch("filter/actionImgFilter", false);
    },
    handleScroll() {
      // console.log(window.scrollY);
      if (window.scrollY > 260) {
        this.scrollNavbar = true;
      } else {
        this.scrollNavbar = false;
      }
    },
    showFilter() {
      // console.log("test111");
      this.$emit("showFilter");
      //   console.log(this.$route);
      //   if (this.$route.name !== "productsfilter" || this.$route.name !== "ProductList") {
      this.hideImg();
      //   }
    },
    sortProducts(sort_by) {
      // Daca e selectata aceeasi optiune de sortare a doua oara, resetam sortarea 
      var resetSorting = 0;
      if(this.sortByValue == sort_by) {
        this.filter_params.sort = this.categ.sort_type;
        this.sortByValue = '';
        resetSorting = 1;
      } else {
        this.sortByValue = sort_by;
        this.filter_params.sort = sort_by;
      }
      this.$store.dispatch("shop/actionSpinner", true);
      this.$store
        .dispatch("shop/getProducts", this.filter_params)
        .then(() => {
          if (resetSorting) {
            if(this.$route.params.filters) {
              this.$router.push({ name: "productsfilter", params: { filters: this.$route.params.filters } });
            } else {
              this.$router.push({ name: "ProductList" });
            }
          } else if (this.$route.params.filters) {
            this.$router.push({
              name: "productsfiltersort",
              params: { filters: this.$route.params.filters, sortby: sort_by },
            });
          } else {
            this.$router.push({ 
              name: "productssort",
              params: { sortby: sort_by }, 
            });
          }
        })
        .then(() => {
          this.$store.dispatch("shop/actionSpinner", false);
        });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
@import "../../assets/css/mobile-filter.css";

.nr-of-filters {
  width: 23px;
  height: 23px;
  background-color: green;
  position: fixed;
  position: absolute;
  right: 48px;
  top: 60px;
  /* top: 56px; */
  border-radius: 18px;
  z-index: 100;
  text-align: center;
  color: white;
}

.no-of-filters {
  color: #000000;
  background: #ffffff;
  border-radius: 50%;
  padding: 2px 6px;
  margin-right: 7px;
}

.filter-mobile-sticky .nr-of-filters {
  top: 100px;
  position: fixed;
}

.sort-button {
  background-color: #000000;
  color: #ffffff;
  font-family: "Montserrat SemiBold";
  font-size: 12px;
  margin-right: 10px;
}

.filter-button {
  background-color: #97040c;
  color: #ffffff;
  font-family: "Montserrat SemiBold";
  font-size: 12px;
}

.filter-button-icon {
  margin-right: 7px;
  top: -1px;
  position: relative;
}

.results {
  font-family: "Montserrat";
  font-size: 12px;
  color: #747171;
}

.sort-modal {
  height: calc(100% - 60px);
  top: 60px !important;
}

.sort-modal-content {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sort-modal-content .sort-modal-header {
  background-color: #fff;
  line-height: 60px;
  border-bottom: solid 1px #cdcbcb;
}
.sort-modal-content .sort-modal-header .main-title {
  font-family: Raleway SemiBold;
  font-size: 14px;
  margin-left: 20px;
}
.sort-modal-content .sort-modal-header .btn-close {
  font-family: Raleway SemiBold;
  font-size: 22px;
  position: absolute;
  right: 0;
  top: 7px;
}

.sort-modal-content .sort-modal-body {
  font-family: Raleway SemiBold;
  flex-grow: 1;
  overflow-y: overlay;
}

.sort-modal-content .sort-modal-footer {
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sort-modal-content .sort-modal-footer .btn-sort {
  background-color: #97040c;
  color: #fff;
  border-radius: 22px;
  font-family: Montserrat SemiBold;
  font-size: 17px;
  padding: 8px 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 88%;
}

.sort-products-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sort-products-list li label {   
  width: 100%;
  border-bottom: solid 1px #cdcbcb;
  background-color: #fff;
  margin: 0;
  padding: 20px;
  font-size: 18px;
  position: relative;
}

.sort-products-list li label:hover, .sort-products-list input[type="radio"]:hover {
  cursor: pointer;
}

.sort-products-list input[type="radio"] {
  appearance: initial;
  -moz-appearance: initial;
  -webkit-appearance: initial;
  height: 20px;
  width: 20px;
  border: 1px solid #a9a9a9;
  background: #f4f4f4;
  border-radius: 10px;
  outline: none;
  vertical-align: text-top;
  margin-right: 15px;
  position: absolute;
  right: 0;
}

.sort-products-list input[type="radio"]:checked::after {
  padding: 19px;
}
.sort-products-list input[type="radio"]:checked,
.sort-products-list .checked input[type="radio"] {
  background-color: #b60000;
}

@media (min-width: 576px) {
  .sort-modal {
    height: calc(100% - 100px);
    top: 100px !important;
  }
}
</style>
